<style lang="less" scoped>
    @import url("~@/lib/base.less");
    .share-component {
      line-height: 1;

      .share {
        .img {
          width: .pxToRem(124) [];
          height: .pxToRem(124) [];
        }
      }
      .web-share {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.6);
        &-img {
          position: absolute;
          top: 0;
          right: 0;
          width: .pxToRem(550) [];
        }
      }
    }
</style>

<template>
    <div class="component share-component">
        <div class="iphone-x-adaptation">
            <div class="share">
                <img class="img"
                     @click="showTip"
                     :src="require('@/assets/img/mg_share.png')" />
            </div>
        </div>
        <div class="web-share"
             v-if="showTipStatus"
             @click="closeTip"
             @touchmove.prevent.stop="closeTip">
            <img class="web-share-img"
                 mode="widthFix"
                 :src="require('@/assets/img/share.png')" />
        </div>

    </div>
</template>
<script>
    import { GET_WX_SHARE_CONFIG } from 'api';
    export default {
        data() {
            return {
                isInit: false,
                showTipStatus: false,
                cssText: '',
            }
        },
        computed: {
            isLogin() {
                return !!this.$store.state.user.token;
            }
        },
        watch: {
            isLogin(val) {
                if (val && !this.isInit) {
                    this.isInit = true;
                    this.setShareConfig();
                }
            },
            showTipStatus(val) {
                if (val) {
                    this.$util.lockScreen();
                }
                else {
                    this.$util.unlockScreen();
                }
            }
        },
        created() {
            if (this.isLogin && !this.isInit) {
                this.isInit = true;
                this.setShareConfig();
            }
            // this.cssText = document.body.style.cssText;
        },
        methods: {
            setShareConfig() {
                this.$util.get(GET_WX_SHARE_CONFIG).then(res => {
                    if (res.success) {
                        let { title, desc, link, imgUrl } = res.data
                        wx.onMenuShareAppMessage({
                            title, // 分享标题
                            desc, // 分享描述
                            link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl, // 分享图标
                        })
                        wx.onMenuShareTimeline({
                            title, // 分享标题
                            link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl, // 分享图标
                        })
                    }
                })
            },
            showTip() {
                if (!this.isLogin) {
                    this.$loginModel.open();
                }
                else {
                    this.showTipStatus = true;
                }

            },
            closeTip() {
                this.showTipStatus = false;
            }
        }
    }
</script>

