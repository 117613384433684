<template>
    <div class="page page-index">
        <div class="con">
            <div class="con-top">
                <div class="title-name">肯德基在线点餐，低至6折起～</div>
            </div>
            <div class="flash"
                 v-if="imgUrls.length > 0">
                <div class="swiper-container banner-swiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide"
                             v-for="(item , index) in imgUrls"
                             :key="index">
                            <img class="slide-image"
                                 :src="item.imageUrl"
                                 alt="" />
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
            <div class="title-name">自助点餐，不支持外卖</div>
            <div class="title-img">
                <img class="image"
                     src="@/assets/kfc-img/kfc-title.png" />
            </div>
            <div class="title-recommend">推荐餐厅</div>
            <div class="position-title"
                 @click="waithRestaurant">
                <img class="left-icon"
                     src="@/assets/kfc-img/position.png" />
                <div class="center-title">
                    <div class="address-name"
                         v-if="queryData[0]">{{queryData[0].storeName}}</div>
                    <div class="address"
                         v-if="queryData[0]">{{queryData[0].address}}</div>
                </div>
                <div class="min-distance"
                     v-if="queryData[0]">{{queryData[0].distance}}</div>
                <div class="update-name">
                    <div class="update-icon">
                        <img class="img"
                             src="@/assets/kfc-img/position-update.png" />
                    </div>
                    <div class="update-title">切换餐厅</div>
                </div>
            </div>
            <div class="title-add">根据您所在的位置，已为您推荐最近的餐厅</div>
            <div class="button-name"
                 @click="handleCommodity">
                <div class="button-border">开始点餐</div>
            </div>
            <!-- <div class="button-title"
                 wx:if="{{kfcTitle}}">{{kfcTitle}}</div> -->
        </div>
        <!-- 分享 -->
        <div class="kfc-order">
            <CustomerService />
            <!-- <Share /> -->
            <img class="img-order"
                 @click="toOrderList"
                 src="@/assets/img/kmy_order.png" />
        </div>
    </div>
</template>
<script>
    import { GET_LARTELY_STOP, GET_BANNER_OR_ICON } from 'api';
    import { PAGE_RESTAURANT_LIST, PAGE_STOP_MENU, PAGE_ORDER_LIST } from 'page';
    import Share from '@/components/share';
    import CustomerService from '@/components/customerService/btn.vue';
    export default {
        data() {
            return {
                isIniting: false,
                pageIndex: 1,
                pageSize: 20,
                pageLocation: {},
                queryData: [],
                imgUrls: [],
                bannerSwiper: null,
            }
        },
        computed: {
            cityId() {
                return this.$store.state.location.currentLocation.city.id || this.$store.state.location.selectLocation.city.id
            },
            userId() {
                return this.$store.state.user.userInfo.id || null
            }
        },
        watch: {
            cityId(val) {
                this.init();
            },
            userId(val) {
                if (val && this.queryData.length == 0) {
                    this.init();
                }
            }
        },
        components: {
            Share,
            CustomerService
        },
        created() {
            if (this.cityId) {
                this.init();
            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
            });
        },
        methods: {
            // init
            init() {
                if (this.isIniting) {
                    return;
                }
                this.isIniting = true;
                if (this.$store.state.location.currentLocation.city.id) {
                    this.pageLocation = this.$store.state.location.currentLocation;
                }
                else {
                    this.pageLocation = this.$store.state.location.selectLocation;
                }
                this.getBanner();
                if (!this.cityId) {
                    this.isIniting = false;
                    return;
                }
                this.getData();
            },
            getBanner() {
                this.$util.get(`${GET_BANNER_OR_ICON}/3`).then(res => {
                    if (res.success) {
                        this.imgUrls = res.data;
                        this.imgUrls && (this.bannerSwiper = this.$nextTick(() => {
                            new this.$swiper(".banner-swiper", {
                                direction: "horizontal",/*横向滑动*/
                                autoplay: true,
                                delay: 3000,
                                pagination: {
                                    el: '.swiper-pagination',
                                    clickable: true,
                                },
                            });
                        }));
                    }
                })
            },
            // 获取推荐餐厅
            getData() {
                if (!this.pageLocation.city.id) {
                    return;
                }
                this.$util.post(GET_LARTELY_STOP, {
                    areaCityId: this.pageLocation.city.id,
                    pageSize: this.pageSize,
                    pageIndex: this.pageIndex,
                    lon: this.pageLocation.lon,
                    lat: this.pageLocation.lat,
                }).then(res => {
                    if (res.success) {
                        this.queryData = res.data;
                    }
                    this.isIniting = false;
                })
            },
            // 切换餐厅
            waithRestaurant() {
                this.$util.pageJump(PAGE_RESTAURANT_LIST)
            },
            // 开始点餐
            handleCommodity() {
                if (!this.queryData[0]) {
                    return
                }
                this.$confirm.open({
                    title: '温馨提示',
                    message: '肯德基点餐为第三方供应商提供的待下单服务。当您支付后，请凭取餐码等候门店屏幕的显示即可取餐。如有疑问请联系在线客服。',
                    confirmText: '继续点餐',
                    success: res => {
                        if (res.success) {
                            this.$util.pageJump(PAGE_STOP_MENU, {
                                storeCode: this.queryData[0].storeCode,
                                storeName: this.queryData[0].storeName,
                                address: this.queryData[0].address,
                            })
                        }
                    },
                })

            },
            // 去订单列表
            toOrderList() {
                this.$util.pageJump(PAGE_ORDER_LIST)
            }
        }
    }
</script>
<style lang="less">
    .swiper-slide {
    }
</style>

<style lang="less" scoped>
    @import url("~@/lib/base.less");
    .con {
      width: 100%;
      .con-top {
        width: 100%;
        height: .pxToRem(50) [];
        background-color: #fff2f2;
        .title-name {
          height: .pxToRem(50) [];
          margin-left: .pxToRem(30) [];
          font-size: .pxToRem(24) [];
          font-weight: 400;
          color: #d62e34;
          line-height: .pxToRem(50) [];
        }
      }
      .flash {
        height: .pxToRem(280px) [];
        width: .pxToRem(702px) [];
        margin: 0 auto;
        margin-top: .pxToRem(20px) [];
        border-radius: .pxToRem(10) [];
        overflow: hidden;
        .slide-image {
          height: .pxToRem(280px) [];
          width: .pxToRem(702px) [];
        }
      }
      > .title-name {
        width: .pxToRem(702px) [];
        margin: .pxToRem(40) [] 0 .pxToRem(30) [] .pxToRem(24) [];
        font-size: .pxToRem(36) [];
        font-weight: 400;
        color: #000000;
      }
      .title-img {
        width: 100%;
        height: .pxToRem(80) [];
        .image {
          width: 100%;
          height: .pxToRem(80) [];
        }
      }
      .title-recommend {
        width: .pxToRem(702px) [];
        margin: .pxToRem(60) [] 0 .pxToRem(30) [] .pxToRem(24) [];
      }
      .position-title {
        width: 100%;
        height: .pxToRem(92px) [];
        display: flex;
        justify-content: space-between;
        .left-icon {
          margin-left: .pxToRem(24) [];
          width: .pxToRem(24) [];
          height: .pxToRem(34) [];
        }
        .center-title {
          width: .pxToRem(400px) [];
          height: .pxToRem(92px) [];
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-left: .pxToRem(20px) [];
        }
        .center-title .address-name {
          width: .pxToRem(400px) [];
          height: .pxToRem(45) [];
          font-size: .pxToRem(32) [];
          font-weight: 400;
          color: #000000;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .address {
            width: .pxToRem(400px) [];
            height: .pxToRem(33) [];
            font-size: .pxToRem(24) [];
            font-weight: 400;
            color: #666666;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .min-distance {
          width: .pxToRem(150) [];
          height: .pxToRem(33) [];
          font-size: .pxToRem(24) [];
          font-weight: 400;
          color: #666666;
          text-align: center;
        }
        .update-name {
          width: .pxToRem(96px) [];
          height: .pxToRem(92px) [];
          margin-right: .pxToRem(28px) [];
          .update-icon {
            width: .pxToRem(96px) [];
            text-align: center;
            height: .pxToRem(41) [];
            .img {
              width: .pxToRem(50) [];
              height: .pxToRem(41) [];
            }
          }
        }
        .update-title {
          width: .pxToRem(96px) [];
          height: .pxToRem(33) [];
          font-size: .pxToRem(24) [];
          font-weight: 400;
          color: #000000;
          margin-top: .pxToRem(15) [];
        }
      }
      .title-add {
        width: .pxToRem(702px) [];
        margin: .pxToRem(20px) [] 0 0 .pxToRem(24) [];
        font-size: .pxToRem(24) [];
        font-weight: 400;
        color: #999999;
      }
      .button-name {
        width: .pxToRem(702px) [];
        margin-left: .pxToRem(24) [];
        margin-top: .pxToRem(60) [];
        height: .pxToRem(90) [];
        text-align: center;
        .button-border {
          width: .pxToRem(702px) [];
          height: .pxToRem(90) [];
          font-size: .pxToRem(32) [];
          font-weight: 600;
          color: #ffffff;
          background: #d62e34;
          box-shadow: 0 .pxToRem(6) [] .pxToRem(20px) [] 0 rgba(239, 90, 90, 0.5);
          border-radius: .pxToRem(50) [];
          line-height: .pxToRem(90) [];
        }
      }
      .button-title {
        width: 100%;
        text-align: center;
        font-size: .pxToRem(24) [];
        font-weight: 400;
        color: #999999;
        margin-top: .pxToRem(18) [];
      }
    }
    .imagesize {
      width: 100%;
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 999;
      image {
        display: inline-block;
        width: .pxToRem(640) [];
        height: .pxToRem(1138) [];
        position: absolute;
        left: .pxToRem(55) [];
        top: calc(50% - .pxToRem(569) []);
        background-color: #fff;
      }
    }

    /* kfx订单 */
    .kfc-order {
      width: .pxToRem(124) [];
      height: .pxToRem(124) [];
      position: fixed;
      bottom: .pxToRem(316) [];
      right: .pxToRem(14) [];
      transition: all 0.5s;
      z-index: 10;
      .share-image {
        width: .pxToRem(124) [];
        height: .pxToRem(124) [];
        margin-bottom: 8rpx;
      }

      .img-order {
        width: .pxToRem(124) [];
        height: .pxToRem(124) [];
      }

      .kefu-image {
        width: .pxToRem(124) [];
        height: .pxToRem(124) [];
      }

      .kefu-btn {
        width: .pxToRem(124) [];
        height: .pxToRem(124) [];
        padding: 0;
        background-color: transparent;
      }
    }
</style>
